import React, { lazy, Suspense } from 'react';
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { Role, State } from 'auth/services/auth/auth.service';
const FlexLoader = lazy(() => import('components/flex-loader/flex-loader.component'));
const RedeemerFragment = lazy(() => import('fragments/redeemer/redeemer.fragment'));
const DisplayRewardCodeQrFragment = lazy(
    () => import('fragments/display-reward-code-qr/display-reward-code-qr.fragment'),
);
const OrganizationSurveyPage = lazy(() => import('fragments/organization-survey/organization-survey.fragment'));
const OrganizationProvider = lazy(() => import('contexts/organization/organization-provider.component'));
const SurveyProvider = lazy(() => import('contexts/survey/survey-provider.component'));
const SurveyPage = lazy(() => import('fragments/survey/survey.fragment'));
const GoogleAuthRedirectPage = lazy(() => import('fragments/google-auth-redirect/google-auth-redirect.fragment'));
const SubscriptionSuccessPage = lazy(() => import('fragments/subscription-success/subscription-success.fragment'));
const VerifyEmailPage = lazy(() => import('auth/fragments/verify-email/verify-email.fragment'));
const ResetPasswordPage = lazy(() => import('auth/fragments/reset-password/reset-password.fragment'));
const WizardPage = lazy(() => import('auth/fragments/wizard/wizard.fragment'));
const AwaitApprovalPage = lazy(() => import('auth/fragments/await-approval/await-approval.fragment'));
const LoginPage = lazy(() => import('auth/fragments/login/login.fragment'));
const RegisterPage = lazy(() => import('auth/fragments/register/register.fragment'));
const ConfirmPaymentPage = lazy(() => import('fragments/confirm-payment/confirm-payment.fragment'));
const CheckCouponPage = lazy(() => import('fragments/check-coupon/check-coupon.fragment'));
export type RoutePrivateProps = RouteProps;

const AuthRoute: React.FC<RoutePrivateProps> = (props) => {
    const pathName = window.location.pathname;
    const [session, , , isLoading] = useLocalSession();

    const isUserAutheticated = Boolean(session.token);
    const isUserEmailValidated = session.user?.isEmailValidated === State.YES;
    const userFinishedWizard = session.user?.hasFinishedWizard;

    return (
        <Suspense>
            <div>
                {isLoading && !pathName.includes('/surveys/') ? (
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <FlexLoader />
                    </div>
                ) : (
                    <Switch>
                        <Route
                            path={'/surveys/:venueId/s/:surveyId'}
                            render={({ match, location }) => {
                                const query = new URLSearchParams(location.search);
                                const isPreview = query.get('isPreview') as unknown as boolean;
                                const color = query.get('color');
                                const textColor = query.get('textColor');
                                return (
                                    <OrganizationProvider
                                        venueId={match.params?.venueId}
                                        isPreview={isPreview}
                                        color={color}
                                        textColor={textColor}
                                    >
                                        <SurveyProvider>
                                            <SurveyPage surveyId={match.params?.surveyId} />;
                                        </SurveyProvider>
                                    </OrganizationProvider>
                                );
                            }}
                        />
                        <Route
                            path={'/redeemer/qr/:code'}
                            render={({ match, location }) => {
                                return <DisplayRewardCodeQrFragment rewardCodeId={match.params.code} />;
                            }}
                        />
                        <Route
                            path={'/redeemer/code/:code'}
                            render={({ match }) => {
                                return (
                                    <div style={{ overflowY: 'hidden' }}>
                                        <RedeemerFragment rewardCodeId={match.params.code} />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            path={'/surveys/:venueId'}
                            render={({ match, location }) => {
                                const query = new URLSearchParams(location.search);
                                const isPreview = query.get('isPreview') as unknown as boolean;
                                const testEmail = query.get('email');
                                const color = query.get('color');
                                const textColor = query.get('textColor');
                                const logoUrl = query.get('logoUrl');
                                const decodedUrl = logoUrl ? decodeURIComponent(logoUrl) : '';
                                if (match.params?.venueId === 'abd8ddf1-ce22-4bed-a42a-597db150e04c') {
                                    return <Redirect to="/surveys/a885f192-9812-44b4-ba6f-c17cb2fe68c7" />;
                                }
                                return (
                                    <OrganizationProvider
                                        venueId={match.params?.venueId}
                                        isPreview={isPreview}
                                        logoUrl={decodedUrl}
                                        testEmail={testEmail}
                                        color={color}
                                        textColor={textColor}
                                    >
                                        <OrganizationSurveyPage />
                                    </OrganizationProvider>
                                );
                            }}
                        />
                        <Route
                            path="/integrations/google"
                            render={({ location }) => {
                                const query = new URLSearchParams(location.search);
                                const token = query.get('token');
                                const organizationId = query.get('orgId');
                                return <GoogleAuthRedirectPage token={token} organizationId={organizationId} />;
                            }}
                        />
                        <Route
                            path={'/payments/success'}
                            render={() => {
                                return <SubscriptionSuccessPage />;
                            }}
                        />
                        <Route
                            path={'/email-confirmation/:token'}
                            render={({ match }) => {
                                return <VerifyEmailPage token={match.params.token} />;
                            }}
                        />
                        <Route path={'/reset-password/:token'}>
                            <ResetPasswordPage />
                        </Route>
                        <Route
                            path={'/wizard'}
                            render={() => {
                                if (
                                    isUserAutheticated &&
                                    isUserEmailValidated &&
                                    !userFinishedWizard &&
                                    session.user?.role !== Role.MANAGER_USER
                                ) {
                                    return <WizardPage />;
                                } else if (!isUserEmailValidated) {
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: '/await-approval',
                                            }}
                                        />
                                    );
                                } else {
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: '/login',
                                            }}
                                        />
                                    );
                                }
                            }}
                        />
                        <Route path={'/await-approval'}>
                            <AwaitApprovalPage />
                        </Route>
                        <Route path={'/login'}>
                            <LoginPage />
                        </Route>
                        <Route path={'/register'}>
                            <RegisterPage />
                        </Route>
                        <Route path={'/confirm-payment'}>
                            <ConfirmPaymentPage />
                        </Route>
                        <Route
                            path={'/check-coupon'}
                            render={({ location }) => {
                                const query = new URLSearchParams(location.search);
                                return <CheckCouponPage coupon={query.get('coupon')} />;
                            }}
                        />
                        <Route
                            render={({ location }) =>
                                (!isLoading && isUserAutheticated && isUserEmailValidated && userFinishedWizard) ||
                                session.user?.role === Role.MANAGER_USER ||
                                session.user?.role === Role.ADMIN_USER ? (
                                    (props.children as React.ReactNode)
                                ) : isUserAutheticated && !isUserEmailValidated ? (
                                    <Redirect
                                        to={{
                                            pathname: '/await-approval',
                                            state: { from: location },
                                        }}
                                    />
                                ) : session.user?.role === Role.ROOT_USER &&
                                  !userFinishedWizard &&
                                  isUserAutheticated ? (
                                    <Redirect
                                        to={{
                                            pathname: '/wizard',
                                            state: { from: location },
                                        }}
                                    />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: location },
                                        }}
                                    />
                                )
                            }
                        />
                    </Switch>
                )}
            </div>
        </Suspense>
    );
};

export default AuthRoute;

import React, { useState, useEffect } from 'react';
import { DefaultSurveyContext, VenueAndSurveyTypeFiltersDto } from './default-survey.context';
import { useVenueContext } from 'tools/venue-hooks/venue-hooks';
import { Form } from 'antd';
import { FindVenueOutput } from 'services/venue/venue.service';

interface DefaultSurveyProviderProps {
    children: React.ReactNode;
}

const DefaultSurveyProvider: React.FC<DefaultSurveyProviderProps> = (props) => {
    const [form] = Form.useForm();
    const { venue } = useVenueContext();
    const [venueAndSurveyTypeFilters, setVenueAndSurveyTypeFilters] = useState<Partial<VenueAndSurveyTypeFiltersDto>>(
        {},
    );
    /* THIS RUNS FIRST TIME IS RENDERED AND EVERY TIME VENUE CHANGES */

    /* IF IT'S FIRST TIME RUNNING, WE WANT TO ASSIGN MODE DEPENDING ON VENUE CONFIGS */

    /* IF VENUE HAS CHANGE, AND NEW VENUE HAS DEFAULT SURVEY TYPE = NONE, WE WANT TO RESPECT THE PREVIOUS STATE OF VENUE */

    /* ELSE, WE WANT TO ASSIGN MODE DEFPENDING ON VENUE CONFIGS */

    useEffect(() => {
        if (venue) {
            onVenueChange(venue);
        }
    }, [venue]);

    const onVenueChange = (venue: FindVenueOutput) => {
        if (venue.globalVenueConfiguration?.mainSurvey) {
            onChangeMode(venue.globalVenueConfiguration?.mainSurvey?.id);
            form.setFieldsValue({ mode: venue.globalVenueConfiguration?.mainSurvey?.id });
        } else if (venue.surveys?.length > 0) {
            const { mode } = form.getFieldsValue();
            if (mode) {
                onChangeMode(venue.surveys?.[0]?.id);
                form.setFieldsValue({ mode: venue.surveys[0].id });
            } else {
                onChangeMode(venue.surveys?.[1]?.id);
                form.setFieldsValue({ mode: venue.surveys[1].id });
            }
        }
        onChangeVenue(venue);
    };

    const onChangeMode = (surveyId?: string) => {
        setVenueAndSurveyTypeFilters((prevValues) => ({
            ...prevValues,
            surveyId,
        }));
    };

    const onChangeVenue = ({ id }: FindVenueOutput) => {
        setVenueAndSurveyTypeFilters((prevValues) => ({
            ...prevValues,
            venueId: id,
        }));
    };

    return (
        <DefaultSurveyContext.Provider value={{ form, venueAndSurveyTypeFilters, onChangeMode }}>
            {props.children}
        </DefaultSurveyContext.Provider>
    );
};

export default DefaultSurveyProvider;

import React from 'react';
import locale from 'antd/es/locale/es_ES';
import ReactDOM from 'react-dom/client';
import 'reflect-metadata';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import 'index.scss';
import 'tools/i18n/i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');
import SessionProvider from 'auth/components/session-provider.component';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { MainFragment } from 'fragments/main/main.fragment';
import BadReviewNotificationsProvider from 'contexts/bad-review-notifications/bad-review-notifications-provider.component';
import VenueProvider from 'contexts/venue/venue-provider.component';
import DefaultSurveyProvider from 'contexts/default-survey/default-survey-provider.component';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={locale}>
                <SessionProvider>
                    <VenueProvider>
                        <DefaultSurveyProvider>
                            <BadReviewNotificationsProvider>
                                <MainFragment />
                            </BadReviewNotificationsProvider>
                        </DefaultSurveyProvider>
                    </VenueProvider>
                </SessionProvider>
            </ConfigProvider>
        </QueryClientProvider>
    </BrowserRouter>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
let refreshing = false;
navigator.serviceWorker?.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { CacaoGoogleIntegration } from 'services/google/google.service';
import { FindVenueOutput } from 'services/venue/venue.service';

export enum Role {
    ADMIN_USER = 'adminUser',
    MANAGER_USER = 'managerUser',
    ROOT_USER = 'rootUser',
    SIMPLE_USER = 'simpleUser',
}

export enum State {
    YES = 'yes',
    NO = 'no',
    PENDING = 'pending',
}

export interface UserDto {
    id: string;
    firstName: string;
    lastName: string;
    password?: string;
    email: string;
    phoneNumber: string;
    isApproved: State;
    isEmailValidated: State;
    role: Role;
    venue: FindVenueOutput;
    hasFinishedWizard?: boolean;
    googleIntegrations?: CacaoGoogleIntegration[] | null;
}

export interface ForeignKeyDto {
    id: string;
    name: string;
}
export interface RegisterInput {
    firstName: string;
    email: string;
    password: string;
    role: Role.ADMIN_USER;
    qubi: boolean;
}
export interface LoginInput {
    password: string;
    username: string;
}
export interface LoginOutput {
    accessToken: string;
    user: UserDto;
    organizationId: string;
    subscription: { status: string };
    venue: FindVenueOutput;
    isTestOrg: boolean;
}
export interface SendResetPasswordMailInput {
    email: string;
}
export interface ResetPasswordInput {
    password: string;
    repeatPassword: string;
}

export interface AuthService {
    register(input: RegisterInput): Promise<LoginOutput>;
    login(input: LoginInput): Promise<LoginOutput>;
    logout(): Promise<void>;
    me(token?: string): Promise<UserDto>;
    sendResetPasswordMail(input: SendResetPasswordMailInput): Promise<void>;
    resetPassword(token: string, input: ResetPasswordInput): Promise<void>;
    verifyEmail(token: string): Promise<boolean>;
}

import {
    Notification,
    GmailNotificationOutput,
    BadReviewNotificationOutput,
} from 'services/notification/notification.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useAPINotification = (): Notification => {
    const [session] = useLocalSession();

    const getBadReviewsNotificationsByUser = async (userId: string): Promise<BadReviewNotificationOutput[]> => {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/notificationToUser?userId=${userId}`, {
            headers: { Authorization: 'Bearer ' + session.token },
        });
        return response.data;
    };

    const updateNotification = async (notificationId: string, userId: string): Promise<void> => {
        const response = await axios.patch(
            process.env.REACT_APP_API_URL + `/notificationToUser?notificationId=${notificationId}&userId=${userId}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data;
    };

    return {
        getBadReviewsNotificationsByUser,
        updateNotification,
    };
};

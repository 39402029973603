import React, { useState, useEffect } from 'react';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPINotification } from 'services/notification/api-notification.service';
import { BadReviewNotificationsContext } from './bad-review-notifications.context';
import { BadReviewNotificationOutput } from 'services/notification/notification.service';
import io, { Socket } from 'socket.io-client';

interface BadReviewNotificationsProviderProps {
    children: React.ReactNode;
}

const BadReviewNotificationsProvider: React.FC<BadReviewNotificationsProviderProps> = (props) => {
    const [session] = useLocalSession();
    const notificationsService = useAPINotification();
    const [notifications, setNotifications] = useState<BadReviewNotificationOutput[]>([]);
    const [socket, setSocket] = useState<Socket | null>(null);
    useEffect(() => {
        const newSocket = io(`${process.env.REACT_APP_API_URL}`);
        newSocket.connect();
        setSocket(newSocket);
        if (session.user) {
            fetchNotificationsByUser(session.user.id);
        }
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('badReviewNotification', (message) => {
                if (session.user && session.user.id) {
                    fetchNotificationsByUser(session.user.id);
                }
            });
        }
    }, [socket]);

    const fetchNotifications = () => {
        if (session.user) {
            notificationsService.getBadReviewsNotificationsByUser(session.user.id).then((response) => {
                setNotifications(response);
            });
        }
    };

    const updateNotification = (id: string, userId: string) => {
        notificationsService.updateNotification(id, userId).then(() => {
            setNotifications((values) => {
                if (values) {
                    const newValues = values.filter((value) => value.id != id);
                    return [...newValues];
                } else return [];
            });
        });
    };

    const fetchNotificationsByUser = (userId: string) => {
        notificationsService.getBadReviewsNotificationsByUser(userId).then((response) => {
            setNotifications(response);
        });
    };

    return (
        <BadReviewNotificationsContext.Provider
            value={{ notifications, setNotifications, fetchNotifications, updateNotification }}
        >
            {props.children}
        </BadReviewNotificationsContext.Provider>
    );
};

export default BadReviewNotificationsProvider;

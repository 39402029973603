import React from 'react';
import { FindVenueOutput } from 'services/venue/venue.service';

export interface VenueContextInterface {
    venue?: FindVenueOutput;
    venues?: FindVenueOutput[];
    setVenue: React.Dispatch<React.SetStateAction<FindVenueOutput | undefined>>;
    fetchVenues: () => void;
}

export const VenueContext = React.createContext<VenueContextInterface>({
    fetchVenues: () => {
        // Nothing to do here
    },
    setVenue: () => {
        // Nothing to do here
    },
});

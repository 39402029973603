import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import {
    Venue,
    CreateVenueInput,
    CreateVenueOutput,
    FindVenueOutput,
    EditVenueSettingsInput,
    CreateVenueBatchInput,
    UpdateVenueLogoInput,
} from 'services/venue/venue.service';
import { useTranslation } from 'react-i18next';

export const useAPIVenue = (): Venue => {
    const [session] = useLocalSession();
    const { i18n } = useTranslation('translation');

    const createVenue = async (input: CreateVenueInput): Promise<CreateVenueOutput> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/venue', input, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as CreateVenueOutput;
        } catch (err: any) {
            throw err.response.data;
        }
    };

    const checkIfVenueExists = async (address: string): Promise<boolean> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/venue/checker',
                { address },
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (err: any) {
            throw err.response.data;
        }
    };

    const createVenueBatch = async (input: CreateVenueBatchInput): Promise<CreateVenueOutput[]> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + `/venue/batch?l=${i18n.language}`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as CreateVenueOutput[];
        } catch (err: any) {
            throw err.response.data;
        }
    };

    const getVenuesByOrg = async (orgId?: string): Promise<FindVenueOutput[]> => {
        const response = await axios.get(
            process.env.REACT_APP_API_URL + `/venue/all?organizationId=${orgId || session.organizationId}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data;
    };

    const getVenue = async (id: string): Promise<FindVenueOutput> => {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/venue/${id}`, {
            headers: { Authorization: 'Bearer ' + session.token },
        });
        return response.data;
    };

    const editVenueSettings = async (input: EditVenueSettingsInput): Promise<void> => {
        try {
            const response = await axios.patch(
                process.env.REACT_APP_API_URL + `/venue/${input.venueId}/settings`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (err: any) {
            throw err.response.data;
        }
    };

    const setVenueLogo = async (input: UpdateVenueLogoInput): Promise<FindVenueOutput> => {
        const response = await axios.patch(process.env.REACT_APP_API_URL + `/venue/logo/set`, input, {
            headers: { Authorization: 'Bearer ' + session.token },
        });
        return response.data as FindVenueOutput;
    };

    const deleteVenue = async (id: string): Promise<boolean> => {
        const response = await axios.delete(process.env.REACT_APP_API_URL + `/venue?id=${id}`, {
            headers: { Authorization: 'Bearer ' + session.token },
        });
        return response.data;
    };

    const getVenueLogo = async (key: string): Promise<number[]> => {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/venue/logo/byKey?key=${key}`, {
            headers: { Authorization: 'Bearer ' + session.token },
        });
        return response.data.data;
    };

    return {
        createVenue,
        getVenue,
        deleteVenue,
        getVenueLogo,
        editVenueSettings,
        getVenuesByOrg,
        createVenueBatch,
        setVenueLogo,
        checkIfVenueExists,
    };
};

import { UserDto } from 'auth/services/auth/auth.service';
import { GoogleMyBusinessComparisonTableDataSource } from 'components/google-my-business-comparison-table/google-my-business-comparison-table.component';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { GoogleKpis } from 'services/metrics/metrics.service';

export interface Session {
    token?: string;
    user?: UserDto;
    organizationId?: string;
    subscription?: { status: string };
    googleMetrics?: {
        googleKpis?: GoogleKpis | undefined;
        googleComparisonDataSource?: GoogleMyBusinessComparisonTableDataSource[];
        state: 'loading' | 'no-integrations' | 'fetched';
    };
    isTestOrg?: boolean;
}

export const isTokenExpired = (token: string): boolean => {
    const decoded = jwtDecode<JwtPayload>(token);
    const expirationTimestamp = decoded.exp;
    if (!expirationTimestamp) {
        return false;
    }
    const todayTimestamp = Math.floor(Date.now() / 1000);
    return expirationTimestamp < todayTimestamp;
};

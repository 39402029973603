import React from 'react';
import { CreateVenueOutput, FindVenueOutput } from 'services/venue/venue.service';

export interface WizardContextInterface {
    venue?: FindVenueOutput;
    venues?: CreateVenueOutput[];
    handleSetVenue: (venue: FindVenueOutput) => void;
    handleSetVenues: (venue: CreateVenueOutput[]) => void;
}

export const WizardContext = React.createContext<WizardContextInterface>({
    handleSetVenue: (venue: FindVenueOutput) => {
        // Nothing to do here...
    },
    handleSetVenues: (venues: CreateVenueOutput[]) => {
        // Nothing to do here...
    },
});

import React, { lazy, Suspense } from 'react';
import 'fragments/main/main.scss';
import { MainFragmentProps } from 'fragments/main/interfaces';
import FlexLoader from 'components/flex-loader/flex-loader.component';
const MainNavComponent = lazy(() => import('nav/main.nav'));

export const MainFragment: React.FC<MainFragmentProps> = (props) => {
    return (
        <div className={'main'}>
            <Suspense fallback={<FlexLoader />}>
                <MainNavComponent />
            </Suspense>
        </div>
    );
};

import React from 'react';
import { BadReviewNotificationOutput, GmailNotificationOutput } from 'services/notification/notification.service';

export interface BadReviewNotificationsContextInterface {
    notifications: BadReviewNotificationOutput[];
    setNotifications: (notifications: BadReviewNotificationOutput[]) => void;
    fetchNotifications: () => void;
    updateNotification: (id: string, userId: string) => void;
}

export const BadReviewNotificationsContext = React.createContext<BadReviewNotificationsContextInterface>({
    fetchNotifications: () => {
        // Nothing to do
    },
    setNotifications: () => {
        // Nothing to do
    },
    updateNotification: (id: string, userId: string) => {
        //Noting to do
    },
    notifications: [],
});

import React from 'react';
import { Layout, Spin } from 'antd';
import 'components/flex-loader/flex-loader.scss';

interface FlexLoaderProps {
    mainColor?: string;
}

const FlexLoader: React.FC<FlexLoaderProps> = (props) => {
    return (
        <div className={'flex-loader'}>
            <Layout>
                <Spin style={{ color: props.mainColor }} />
            </Layout>
        </div>
    );
};

export default FlexLoader;

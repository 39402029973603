import { FormInstance } from 'antd';
import React from 'react';

export interface VenueAndSurveyTypeFiltersDto {
    venueId: string;
    surveyId: string;
}
export interface DefaultSurveyContextInterface {
    form?: FormInstance;
    venueAndSurveyTypeFilters: Partial<VenueAndSurveyTypeFiltersDto>;
    onChangeMode: (surveyId: string) => void;
}

export const DefaultSurveyContext = React.createContext<DefaultSurveyContextInterface>({
    venueAndSurveyTypeFilters: {},
    onChangeMode: (surveyId: string) => {
        // Nothing to do here
    },
});

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esES from 'locales/es/translations.json';
import enEN from 'locales/en/translations.json';

i18n.use(initReactI18next)
    .init({
        lng: 'es',
        resources: {
            es: { translation: esES },
            en: { translation: enEN },
        },
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
    .finally();

import React, { useEffect, useState } from 'react';
import { SESSION_STORAGE_SESSION_KEY } from 'auth/helpers/session.hooks';
import { SessionContext } from 'auth/helpers/session.context';
import { Session } from 'auth/helpers/session';
import AuthRoute from 'auth/components/auth-route.component';
import WizardProvider from 'contexts/wizard/wizard-provider.component';
interface SessionProviderProps {
    children: React.ReactNode;
}

const SessionProvider: React.FC<SessionProviderProps> = (props) => {
    const [session, setSession] = useState<Session>({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadSessionFromLocalStorage().finally();
    }, []);

    const loadSessionFromLocalStorage = async () => {
        const storedSession = await window.sessionStorage.getItem(SESSION_STORAGE_SESSION_KEY);
        if (storedSession) {
            const sessionModel = JSON.parse(storedSession) as Session;
            setSession(sessionModel);
        }
        setIsLoading(false);
    };

    return (
        <SessionContext.Provider value={{ isLoading, session, setSession }}>
            <WizardProvider>
                <AuthRoute>{props.children}</AuthRoute>
            </WizardProvider>
        </SessionContext.Provider>
    );
};

export default SessionProvider;

import React, { useState } from 'react';
import { WizardContext } from './wizard.context';
import { CreateVenueOutput, FindVenueOutput } from 'services/venue/venue.service';

interface WizardProviderProps {
    children: React.ReactNode;
}

const WizardProvider: React.FC<WizardProviderProps> = (props) => {
    const [venue, setVenue] = useState<FindVenueOutput>();
    const [venues, setVenues] = useState<CreateVenueOutput[]>();

    const handleSetVenue = (venue: FindVenueOutput) => {
        setVenue(venue);
    };

    const handleSetVenues = (venues: CreateVenueOutput[]) => {
        setVenues(venues);
    };

    return (
        <WizardContext.Provider value={{ venue, venues, handleSetVenue, handleSetVenues }}>
            {props.children}
        </WizardContext.Provider>
    );
};

export default WizardProvider;
